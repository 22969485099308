<template>
  <div class="login">
    <div v-if="loading" class="loading"></div>
    <div v-else-if="recovered">
      {{ mail }} に案内メールを送りましたので、ご確認ください。
    </div>
    <div v-else-if="registered">
      {{ mail }} に登録案内メールを送りましたので、ご確認ください。
    </div>
    <div v-else>
      <div v-if="toRegister">
        <h2>新規登録</h2>
      </div>
      <div v-else-if="inputRecovery">
        <h2>アカウントの復元</h2>
      </div>
      <div v-else>
        <h2>ログイン</h2>
      </div>
      <label>メールアドレス<br/>
      <input v-model="mail" @input="mailInput" @keydown.enter="next"/>
      </label><br/>
      <div v-if="toRegister">
        <button @click="requestRegister" :disabled="disableNext">確認メールを送る</button><br/>
        <button @click="back">戻る</button>
      </div>
      <div v-else-if="inputRecovery">
        <button @click="recover" :disabled="disableNext">復元</button><br/>
        <button @click="back">戻る</button>
      </div>
      <div v-else>
        <label>パスワード<br/>
        <input id="password" type="password" v-model="password" @input="passwordInput" @keydown.enter="login"/>
        </label><br/>
        <button @click="login" :disabled="disableLogin">ログイン</button><br/>
        <button @click="register">新規登録</button>
        <div>
          <a href="#" @click="recovery">パスワードを忘れた方はこちら</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config'

export default {
	name: 'Login',
	data() {
		return {
      mail: '',
      password: '',
      toRegister: false,
      inputRecovery: false,
      disableLogin: true,
      registered: false,
      disableNext: true,
      recovered: false,
      loading: false
		}
	},
	methods: {
    mailInput() {
      this.disableNext = this.mail == '';
      this.disableLogin = this.password.length < 10 || this.mail == '';
    },
    passwordInput() {
      this.disableLogin = this.password.length < 10 || this.mail == '';
    },
    next() {
      if (this.toRegister) {
        this.requestRegister();
        return;
      }
      if (this.inputRecovery) {
        this.recover();
        return;
      }
      document.getElementById('password').focus();
    },
    back() {
      this.toRegister = this.inputRecovery = false;
    },
    register() {
      this.toRegister = true;
    },
    requestRegister() {
      this.loading = true;
      axios.post(config.API+'register.php', {
        mail: this.mail
      }).then(res => {
        if (res.data.status == 'success') {
          this.registered = true;
        }
        else {
          // 失敗
          alert(res.data.message);
        }
        this.loading = false;
      });
    },
    login() {
      this.loading = true;
      axios.post(config.API+'login.php', {
        mail: this.mail,
        password: this.password
      },
      {
        withCredentials: true
      }).then(res => {
        if (res.data.status == 'success') {
          // リロード
          this.$router.push({path: '/', force: true});
        }
        else {
          // 失敗
          alert(res.data.message);
        }
        this.loading = false;
      });
    },
    recovery() {
      this.inputRecovery = true;
    },
    recover() {
      this.loading = true;
      axios.post(config.API+'recovery.php', {
        mail: this.mail
      }).then(res => {
        if (res.data.status == 'success') {
          this.recovered = true;
        }
        else {
          // 失敗
          alert(res.data.message);
        }
        this.loading = false;
      });
    }
	}
}
</script>

<style scoped>
.login {
	margin: 4pt;
  text-align: center;
}
.login input,
.login button {
	margin: 4pt 0;
}
</style>
